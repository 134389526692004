import { useEffect, useRef, useState } from 'react'
import useSWRInfinite from 'swr/infinite'
import withAuth from '../../../components/data/withAuth'
import withCommunity from '../../../components/data/withCommunity'
import FeedPost from '../../../components/feedPost'
import Layout from '../../../components/layout'
import API from '../../../lib/client'
import { Transition } from '@headlessui/react'
import CircleLoader from '../../../components/ui/circleLoader'
import Link from 'next/link'
import { flattenPosts } from '../../../lib/utils/posts'
import moment from 'moment'
import getEnv from '../../../lib/getEnv'
import Alert from '../../../components/ui/alert'
import Button from '../../../components/ui/button'
import {
  faArrowRight,
  faCheck,
  faGear,
  faPlus,
  faRocket,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import CommunitySetupModal from '../../../components/communitySetupModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import withSnowball from '../../../components/data/withSnowball'
import qs from 'query-string'
import { faCopy } from '@fortawesome/free-regular-svg-icons'
import LoginModal from '../../../components/loginModal'
import ComposerDialog from '../../../components/composerDialog'
import ReadMore from '../../../components/ui/readMore'
import Head from 'next/head'
import SocialTags from '../../../components/socialTags'
import MintButton from '../../../components/mintButton'
import Identicon from 'react-identicons'
import { canMintToken, useStateWithPromise } from '../../../lib/utils/misc';

const slugifyText = (text) => {
  // get first 5 words of text, if possible
  const cleaned = text.replace(/[^a-z0-9\ ]/gi, '')
  if (!cleaned) {
    return null
  }

  try {
    const firstFive = text.replace(/[^a-z0-9\ ]/gi, '').match(/\S+/g).slice(0, 5);
    return firstFive.map(t => t.toLowerCase()).join('-');
  } catch {
    return 'p';
  }
}

function Community(props) {
  const { user, mutateUser, community, mutateCommunity, snowball } = props

  const { mcode } = props.query;
  const mintBlocked = !canMintToken(community?.roles, community?.tokens?.[0], mcode);

  const [feedLoading, setFeedLoading] = useState(false);
  const [newPostsRequestOut, setNewPostsRequestOut] = useStateWithPromise(false);

  const [pinnedPosts, setPinnedPosts] = useState(null);
  const [pinnedPostsRequestOut, setPinnedPostsRequestOut] = useState(false);
  const [pinnedPostsRequestError, setPinnedPostsRequestError] = useState(null);

  const [noMorePosts, setNoMorePosts] = useState(false);
  const [postOut, setPostOut] = useState(false);
  const [postError, setPostError] = useState(null);
  const [post, setPost] = useState({});
  const [composerOpen, setComposerOpen] = useState(false);
  const [joinWhitelistRequestOut, setJoinWhitelistRequestOut] = useState(false);
  const [joinWhitelistError, setJoinWhitelistError] = useState(null);
  const [waitlistLoginModalOpen, setWaitlistLoginModalOpen] = useState(false);
  const [mintRequestOut, setMintRequestOut] = useState(false);
  const [mintLoginModalOpen, setMintLoginModalOpen] = useState(false);

  const [closedWaitlistPanel, setClosedWaitlistPanel] = useState(false)

  const [directory, setDirectory] = useState(null)
  const [directoryRequestOut, setDirectoryRequestOut] = useState(false)
  const [directoryError, setDirectoryError] = useState(null)
  const [isClient, setIsClient] = useState(false);

  const env = getEnv()
  let baseUrl = 'https://api.withcomet.com/comet'
  if (env === 'dev') {
    baseUrl = 'https://api.withcomet.dev/comet'
  } else if (env === 'local') {
    baseUrl = 'http://localhost:9000/comet'
  }

  const rawAPI = new API(baseUrl)
  const api = new API()

  const { roles } = community

  useEffect(() => {
    setIsClient(true);
  }, []);

  let roleObj = {}
  if (community && community.access && community.access.accessVersion === 2) {
    for (let i = 0; i < community.access.rules.length; i += 1) {
      roleObj[community.access.rules[i].id] = community.access.rules[i]
    }
  }

  const needsCommunitySetup =
    !!roles &&
    roles.includes('admin') &&
    !(
      community &&
      roles &&
      Object.keys(community.access.rules).length >= 2 &&
      ((!!roleObj.first_membership_pass &&
        community.tokens?.[0]?.metadata?.deployed) ||
        (!!roleObj.prelaunch_whitelist && community.whitelists.length > 0))
    )

  useEffect(() => {
    if (mutateCommunity) mutateCommunity()
  }, [(user || {}).id])

  const noFeed = () => {
    return null
  }

  const getFeedKey = (pageIndex, prevPageData) => {
    if (pageIndex > 0 && prevPageData && !prevPageData.posts) return null
    if (prevPageData && (!prevPageData.posts || !prevPageData.posts.length))
      return null

    if (pageIndex === 0) return `/api/proxy/community/${community.id}/posts`

    // before date is the latest post's timestamp
    const latest = moment(
      prevPageData.posts[prevPageData.posts.length - 1].timestamp
    ).format()
    return `/api/proxy/community/${
      community.id
    }/posts?before=${encodeURIComponent(latest)}`
  }

  const {
    data: feed,
    error: feedError,
    size: feedSize,
    setSize: setFeedSize,
    mutate: mutateFeed,
    isValidating: validatingFeed,
  } = useSWRInfinite(getFeedKey, {
    refreshInterval: 30000,
  })

  const postsPrivate =
    community &&
    roles &&
    !roles.includes('viewPosts') &&
    isClient

  const loadBoxRef = useRef(null)

  const getPinnedPosts = async (optimisticPinnedPosts) => {
    setPinnedPostsRequestOut(true);

    if (optimisticPinnedPosts) {
      setPinnedPosts(optimisticPinnedPosts.sort((a, b) => {
        return moment(b.timestamp) - moment(a.timestamp);
      }));
    }

    try {
      const { posts: pPosts } = await api.get(`/community/${community.id}/posts/pinned`);
      setPinnedPosts(pPosts);
      setPinnedPostsRequestError(null);
    } catch (e) {
      setPinnedPostsRequestError(e);
      setPinnedPosts(null);
    }

    setPinnedPostsRequestOut(false);
  };

  useEffect(() => {
    if (community && community.id) getPinnedPosts();
    setInterval(() => {
      if (community && community.id) getPinnedPosts();
    }, 60000);
  }, [community]);

  // useEffect(() => { console.log(pinnedPosts) }, []);

  useEffect(() => {
    // when validation ends, stop new posts
    if (!validatingFeed && newPostsRequestOut) {
      setNewPostsRequestOut(false)
    }
  }, [newPostsRequestOut, validatingFeed])

  const scrollHandler = async () => {
    if (loadBoxRef && loadBoxRef.current) {
      const bottomDistance =
        loadBoxRef.current.offsetTop +
        loadBoxRef.current.offsetHeight -
        window.pageYOffset -
        window.innerHeight
      if (!validatingFeed && bottomDistance < 10) {
        await setNewPostsRequestOut(true)
        setFeedSize(feedSize + 1)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  })

  useEffect(() => {
    setFeedSize(1)
  }, [community])

  useEffect(() => {
    setFeedLoading(!feed && !feedError)
  }, [feed, feedError])

  const starPinnedPost = async (postId, up = true) => {
    const posts = [...pinnedPosts];

    let cast;

    for (let i = 0; i < posts.length; i += 1) {
      if (posts[i].id === postId) {
        const newPost = {...posts[i]};
        if (posts[i].myUpvote === undefined) {
          // add my vote to post
          newPost.myUpvote = 1;
          newPost.upvotes = posts[i].upvotes ? posts[i].upvotes + 1 : 1;
          cast = true;
        } else if (posts[i].myUpvote === 1) {
          newPost.myUpvote = undefined;
          newPost.upvotes -= 1;
          cast = false;
        }
        posts[i] = newPost;
      }
    }

    setPinnedPosts(posts);

    await api.post(
      `/community/${props.community.id}/posts/${postId}/upvote`,
      { cast, up: true }
    )

    getPinnedPosts();
  }

  const starPost = async (postId, up = true) => {
    const posts = flattenPosts(feed)
    let post = undefined
    let cast = true

    for (let i = 0; i < posts.length; i += 1) {
      if (posts[i].id === postId) {
        post = posts[i]

        let newVote = 1
        if (post.myUpvote === newVote) {
          cast = false
        }

        // first calculate what this new posts looks like
        let newVoteTotal = post.upvotes || 0
        if (post.myUpvote === undefined) {
          newVoteTotal += 1
        } else {
          if (cast) {
            newVoteTotal = newVoteTotal - post.myUpvote + 1
          } else {
            newVoteTotal -= 1
          }
        }

        const newPost = {
          ...post,
          upvotes: newVoteTotal,
        }

        if (cast) {
          newPost.myUpvote = 1
        } else {
          newPost.myUpvote = undefined
        }

        const newFeed = [...posts]
        newFeed[i] = newPost

        mutateFeed([{ posts: newFeed }], false)

        await api.post(
          `/community/${props.community.id}/posts/${postId}/upvote`,
          { cast, up: true }
        )

        mutateFeed()

        break
      }
    }
  }

  const onPostSubmit = async () => {
    setPostOut(true)

    // get one-time use token from regular API
    const { string: ottString } = await api.post('/auth/ott', {
      expireSeconds: 60,
      reason: 'create_post',
    })

    const data = new FormData()
    data.append('title', post.title)
    if (post.content) data.append('content', post.content)
    data.append('type', post.type)

    if (post.type === 'vote') {
      data.append('voteChoices', JSON.stringify(post.voteOptions))
      data.append('voteEndDate', moment(post.voteEnds).utc().format())
      data.append('voteWeighting', post.voteWeighting)
    }

    if (post.file) {
      data.append('image', post.file)
    }

    try {
      // const newFeed = [{ posts: [{}] }, ...feed];
      const p = {
        id: `newpost_${new Date().getTime()}`,
        timestamp: moment().add(10, 'second').format(),
        user,
        ...post,
        optimistic: true,
      }

      mutateFeed([{ posts: [p] }, ...feed], false)
      setPost({})

      const newPost = await rawAPI.post(
        `/community/${community.id}/posts`,
        data,
        false,
        { 'X-Comet-OTT': ottString }
      )

      setPostOut(false)
      setComposerOpen(false)

      // update feed
      mutateFeed()

      // Router.push(`/c/${community.domain}/posts/${post.id}`);
    } catch (e) {
      setPostOut(false)
      setPostError(e)
    }
  }

  let wlcode
  if (isClient) {
    wlcode = qs.parse(window.location.search).wlcode
  }

  const showWhitelist =
    community &&
    community.whitelists &&
    community.whitelists.length === 1 &&
    community.whitelists[0].name === 'Prelaunch Waitlist' &&
    (!community.whitelists[0].private ||
      (community.whitelists[0].private && wlcode != undefined) ||
      (roles && roles.includes('admin')) ||
      (roles && roles.includes('createWhitelists')))

  let whitelist
  let whitelistFull
  if (showWhitelist) {
    whitelist = community.whitelists[0]
    whitelistFull =
      whitelist.maxUsers >= 0 && whitelist.stats.joined >= whitelist.maxUsers
  }

  const showToken =
    community && community.tokens && community.tokens[0]?.metadata?.deployed
  const tokenInfo = {}
  if (showToken) {
    const token = community.tokens[0]
    // const baseUrl = getEnv() === 'prod' ? 'https://'
    const nftImage = `https://${getEnv() === 'prod' ? 'api.' : 'api-dev.'}withcomet.com/comet/token/${token.id}/metadata/0/image.svg`;
    tokenInfo.id = token.id;
    tokenInfo.nftImage = nftImage;
    tokenInfo.name = token.name;
    tokenInfo.description = token.metadata?.config?.description || 'No description provided.';
    tokenInfo.nftRoles = community.access.rules.filter(rule => rule.id === 'first_membership_pass')[0].applyRoles;
    tokenInfo.price = token.metadata?.config?.price;
    tokenInfo.isPrivate = !!token.metadata?.config?.mintPrivate;
    tokenInfo.showToken = !tokenInfo.isPrivate || (tokenInfo.isPrivate && !!mcode);
    // console.log(tokenInfo.nftRoles);
  }

  const onJoinWhitelist = async () => {
    setJoinWhitelistRequestOut(true)

    try {
      const joinQuery = {}
      if (qs.parse(window.location.search).wlcode) {
        joinQuery.code = qs.parse(window.location.search).wlcode
      }

      // once address is filled in, we can join the waitlist
      await api.post(
        `/community/${community.id}/whitelist/${whitelist.id}/join`,
        joinQuery
      )

      await mutateCommunity()
      setJoinWhitelistRequestOut(false)
    } catch (e) {
      setJoinWhitelistError(e)
      setJoinWhitelistRequestOut(false)
    }
  }

  // const onMintClicked = async () => {
  //   const { snowball, community: { tokens: [...tokens] } } = props;
  //   if (snowball && tokens.length && tokens[0].metadata?.config?.publicKey) {
  //     setMintRequestOut(true);
  //     await mint(
  //       api,
  //       snowball,
  //       tokens[0].id,
  //     );
  //     setMintRequestOut(false);
  //   }
  // }

  const loadingSection = () => {
    return (
      <div className="flex w-full items-center justify-center p-4">
        <CircleLoader size={36} />
      </div>
    )
  }

  const humanReadableRoles = {
    viewPosts: 'View posts',
    admin: 'Project administration',
    createWhitelists: 'Create waitlists',
    launchTokens: 'Launch tokens',
    upvotePosts: 'Star posts',
    commentPosts: 'Comment on posts',
    castVotes: 'Vote in polls',
    createPosts: 'Create posts',
  }

  const sidebar = (
    <div className="flex flex-col gap-3 px-2">
      {/* <Disclosure
        title="Description"
        content={community.description || 'None provided.'}
        icon={faCircleInfo}
        startOpen={true}
      /> */}

      {/* <Disclosure
        title="Permissions"
        content={(
          <>
            {
              !!roles && roles.map(role => (
                <div className='flex flex-row gap-2 items-center'>
                  <FontAwesomeIcon icon={faCheck} />
                  <p>{humanReadableRoles[role]}</p>
                </div>
              ))
            }
          </>
        )}
        icon={faLockOpen}
      /> */}
    </div>
  )

  let communityImage
  if (community.bannerPicUpload) {
    communityImage = `https://${
      getEnv() === 'prod' ? 'prod' : 'dev'
    }.cometuploads.com/${community.bannerPicUpload}`
  } else {
    communityImage = 'https://prod.cometuploads.com/yee.png'
  }

  useEffect(() => {
    (async () => {
      setDirectoryRequestOut(true)

      try {
        const directory = await api.get(`/community/${community.id}/directory`)
        setDirectory(directory)
      } catch (e) {
        setDirectoryError(e)
      }

      setDirectoryRequestOut(false)
    })()
  }, [community])

  const onPinPost = async (postId, pin = true) => {
    // first optimistically set post to pinned
    const flattened = flattenPosts(feed);
    let newPinnedPosts = [...pinnedPosts];
    let newFeed = [...flattened];

    if (pin) {
      let thePost;
      for (let i = 0; i < flattened.length; i += 1) {
        if (flattened[i].id === postId) {
          thePost = flattened[i];
        }
      }
      newPinnedPosts.push({
        ...thePost,
        pinned: true,
      });
      newFeed = newFeed.map(post => ({
        ...post,
        pinned: postId === post.id ? true : post.pinned,
      }));
    } else {
      newFeed = newFeed.map(post => ({
        ...post,
        pinned: postId === post.id ? false : post.pinned,
      }));
      newPinnedPosts = newPinnedPosts.filter(post => post.id !== postId);
    }

    setPinnedPosts(newPinnedPosts.sort((a, b) => {
      return moment(b.timestamp) - moment(a.timestamp);
    }));
    mutateFeed([{ posts: newFeed }], false);

    // pin the post
    await api.post(
      `/community/${community.id}/posts/${postId}/pin`,
      {
        pinned: pin,
      },
    );

    // refetch pinned + main posts
    getPinnedPosts();
    mutateFeed();
  }

  // console.log(flattenPosts(feed));

  return (
    <Layout>
      <Head>
        <title>{community.name} | Comet</title>
        <SocialTags
          title={community.name}
          description={community.description}
          image={communityImage}
          url={`https://${community.domain}.withcomet.com`}
        />
      </Head>
      {isClient && (
        <CommunitySetupModal
          open={needsCommunitySetup}
          // community={community}
          // mutateCommunity={mutateCommunity}
          user={user}
        />
      )}
      <LoginModal
        open={waitlistLoginModalOpen}
        onLogin={async () => {
          await mutateUser()
          setWaitlistLoginModalOpen(false)
          onJoinWhitelist()
        }}
        onClose={() => {
          setWaitlistLoginModalOpen(false)
        }}
      />

      <LoginModal
        open={mintLoginModalOpen}
        onLogin={async () => {
          await mutateUser()
          setMintLoginModalOpen(false)
        }}
        onClose={() => {
          setMintLoginModalOpen(false)
        }}
      />

      <div className="flex w-full items-start justify-center">
        <div className="w-full">
          {/* Header */}
          <div className="relative mb-12 h-40 w-full bg-slate-700 sm:mb-16 sm:h-52 sm:rounded-b-3xl md:mb-20">
            <img
              className="absolute h-full w-full border-none object-cover sm:rounded-b-3xl"
              src={`https://${
                env === 'prod' ? 'prod.' : 'dev.'
              }cometuploads.com/${community.bannerPicUpload}`}
            />
          </div>

          {/* Basic header content */}
          <div className="mx-auto max-w-lg w-screen">
            <div className="xs:left-0 relative relative -top-24 left-2 -mb-24 h-24 w-24 overflow-hidden rounded-full border-4 border-white bg-slate-300 sm:-top-28 sm:-mb-28 md:-top-32 md:-mb-32 md:h-32 md:w-32">
              <img
                className="absolute h-full w-full border-none object-cover"
                src={`https://${
                  env === 'prod' ? 'prod.' : 'dev.'
                }cometuploads.com/${community.profilePicUpload}`}
              />
            </div>

            <div className="flex w-full flex-row">
              <div className="grow p-4">
                <h1 className="text-2xl font-bold text-slate-900">
                  {community.name}
                </h1>

                {community.description && <p>{community.description}</p>}
                {directory ? (
                  <a
                    class="my-3 flex items-center"
                    href={`/c/${props.community.domain}/directory`}
                  >
                    <div className='flex -space-x-4 mr-2'>
                      {directory.slice(0,3).map((item) => (
                        <div
                          class="h-10 w-10 rounded-full border-2 border-white overflow-hidden p-1.5 bg-slate-900"
                          src="https://prod.cometuploads.com/yee.png"
                          alt=""
                        > <Identicon string={item.id} size={25} /></div>
                      ))}
                      {
                        directory.length > 3 &&
                        <a class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-white bg-gray-700 text-xs font-medium text-white">
                          +{directory.length - 3}
                        </a>
                      }
                    </div>
                    <p className='mr-2'>{directory.length} members</p>
                    <FontAwesomeIcon icon={faArrowRight} className='w-4' />
                  </a>
                ) : (
                  <CircleLoader size={32}></CircleLoader>
                )}
              </div>
              {((community.memberSettings &&
                !!roles &&
                roles.includes('viewPosts')) ||
                (roles && roles.includes('admin'))) && (
                <div className="p-4 pl-0">
                  <Button
                    size="lg"
                    rightIcon={faGear}
                    variant="secondary"
                    href={`/c/${props.community.domain}/settings`}
                  />
                </div>
              )}
            </div>

            {!closedWaitlistPanel && (
              <div>
                {showWhitelist && (
                  <div className="mb-1 w-full px-2">
                    <div className="w-full rounded-2xl bg-slate-100 p-3">
                      {whitelist.maxUsers >= 0 &&
                        whitelist.stats.joined < whitelist.maxUsers && (
                          <>
                            <p className="mb-1 flex flex-row items-center text-lg font-medium text-slate-900">
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={whitelist.joined ? faCheck : faRocket}
                              />
                              {whitelist.joined
                                ? "You're on the waitlist"
                                : 'Join the waitlist'}
                              <div className="grow" />
                              {(whitelist.joined || whitelistFull) && (
                                <Button
                                  leftIcon={faXmark}
                                  variant="secondary"
                                  onClick={() => {
                                    setClosedWaitlistPanel(true)
                                  }}
                                />
                              )}
                            </p>
                            <p className="mb-1 text-base text-slate-700">
                              {whitelist.joined ? (
                                <>
                                  You now can participate in the{' '}
                                  <b>{community.name}</b> community.
                                </>
                              ) : (
                                <>
                                  To participate in the <b>{community.name}</b>{' '}
                                  community and get exclusive access to updates,
                                  join the waitlist!
                                </>
                              )}
                            </p>
                            <div className="my-3">
                              <div className="h-6 w-full overflow-hidden rounded-lg bg-slate-200">
                                <div
                                  className="h-6 bg-slate-500"
                                  style={{
                                    width: `${
                                      (whitelist.stats.joined /
                                        whitelist.maxUsers) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              {whitelist.maxUsers >= 0 &&
                                whitelist.stats.joined < whitelist.maxUsers && (
                                  <p className="text-sm font-medium text-slate-600">
                                    {whitelist.maxUsers -
                                      whitelist.stats.joined}{' '}
                                    out of {whitelist.maxUsers} slots left
                                  </p>
                                )}
                            </div>
                            {(roles.includes('admin') ||
                              roles.includes('createWhitelists')) &&
                              whitelist.private && (
                                <Button
                                  leftIcon={faCopy}
                                  variant="secondary"
                                  onClick={() => {
                                    window.navigator.clipboard.writeText(
                                      `${window.location.origin}/c/${community.domain}?wlcode=${whitelist.joinCode}`
                                    )
                                  }}
                                >
                                  Copy waitlist link
                                </Button>
                              )}
                          </>
                        )}
                      {whitelist.maxUsers >= 0 &&
                        whitelist.stats.joined >= whitelist.maxUsers && (
                          <>
                            <p className="mb-1 flex flex-row items-center text-lg font-medium text-slate-900">
                              <FontAwesomeIcon
                                className="mr-2"
                                icon={whitelist.joined ? faCheck : faRocket}
                              />
                              {whitelist.joined
                                ? "You're on the waitlist"
                                : 'Waitlist is full'}
                              <div className="grow" />
                              {(whitelist.joined || whitelistFull) && (
                                <Button
                                  leftIcon={faXmark}
                                  variant="secondary"
                                  onClick={() => {
                                    setClosedWaitlistPanel(true)
                                  }}
                                />
                              )}
                            </p>
                            <div className="my-3">
                              <div className="h-6 w-full overflow-hidden rounded-lg bg-slate-200">
                                <div
                                  className="h-6 bg-slate-500"
                                  style={{
                                    width: `${
                                      (whitelist.stats.joined /
                                        whitelist.maxUsers) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                              <p className="text-sm font-medium text-slate-600">
                                All {whitelist.maxUsers} slots taken
                              </p>
                            </div>
                          </>
                        )}
                      {!whitelist.joined && whitelist.maxUsers === -1 && (
                        <>
                          <p className="mb-1 flex flex-row items-center text-lg font-bold text-slate-900">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={whitelist.joined ? faCheck : faRocket}
                            />
                            Join the waitlist
                            <div className="grow" />
                            {(whitelist.joined || whitelistFull) && (
                              <Button
                                leftIcon={faXmark}
                                variant="secondary"
                                onClick={() => {
                                  setClosedWaitlistPanel(true)
                                }}
                              />
                            )}
                          </p>
                          <p className="text-base text-slate-700">
                            To participate in the <b>{community.name}</b>{' '}
                            community and get exclusive access to updates, join
                            the waitlist!{' '}
                            <span className="font-medium">
                              {whitelist.stats.joined}
                            </span>{' '}
                            people have already joined.
                          </p>
                        </>
                      )}
                      {whitelist.joined && whitelist.maxUsers === -1 && (
                        <div>
                          <p className="mb-1 flex flex-row items-center text-lg font-bold text-slate-900">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={whitelist.joined ? faCheck : faRocket}
                            />
                            You're on the waitlist
                            <div className="grow" />
                            {(whitelist.joined || whitelistFull) && (
                              <Button
                                leftIcon={faXmark}
                                variant="secondary"
                                onClick={() => {
                                  setClosedWaitlistPanel(true)
                                }}
                              />
                            )}
                          </p>
                          {(roles.includes('admin') ||
                            roles.includes('createWhitelists')) &&
                            whitelist.private && (
                              <Button
                                leftIcon={faCopy}
                                variant="secondary"
                                onClick={() => {
                                  window.navigator.clipboard.writeText(
                                    `${window.location.origin}/c/${community.domain}?wlcode=${whitelist.joinCode}`
                                  )
                                }}
                              >
                                Copy waitlist link
                              </Button>
                            )}
                        </div>
                      )}
                      {!whitelist.joined &&
                        (whitelist.maxUsers === -1 ||
                          (whitelist.maxUsers >= 0 &&
                            whitelist.stats.joined < whitelist.maxUsers)) && (
                          <div className="mt-1 flex w-full flex-row items-center gap-2">
                            <Button
                              rightIcon={faArrowRight}
                              onClick={
                                !props.user || !props.user.loggedIn
                                  ? () => {
                                      setWaitlistLoginModalOpen(true)
                                    }
                                  : onJoinWhitelist
                              }
                              loading={joinWhitelistRequestOut}
                            >
                              Join
                            </Button>
                            {(roles.includes('admin') ||
                              roles.includes('createWhitelists')) &&
                              whitelist.private && (
                                <Button
                                  leftIcon={faCopy}
                                  variant="secondary"
                                  onClick={() => {
                                    window.navigator.clipboard.writeText(
                                      `${window.location.origin}/c/${community.domain}?wlcode=${whitelist.joinCode}`
                                    )
                                  }}
                                >
                                  Copy waitlist link
                                </Button>
                              )}
                          </div>
                        )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {showToken && (
              <div className="mb-1 w-full px-2">
                <div className="w-full rounded-2xl bg-slate-100 p-3">
                  <div className="flex w-full flex-row items-start gap-4">
                    {/* NFT Preview */}
                    <div className='w-full flex flex-row gap-4 items-start'>
                      <div className='w-full flex flex-col gap-3'>
                        <div>
                          <h3 className='font-medium text-sm text-slate-500 -mb-1'>PASS</h3>
                          <h3 className='font-bold text-xl text-slate-900'>{tokenInfo.name}</h3>
                        </div>

                        <div>
                          <h3 className='font-medium text-xs text-slate-500'>DESCRIPTION</h3>
                          <ReadMore showHeight={150}>
                            <p className='mb-2 text-base font-medium text-slate-700'>{tokenInfo.description}</p>
                          </ReadMore>
                        </div>

                        {
                          (
                            !!roles
                            && roles.includes('admin')
                            && !community.needsClientLoaded
                            && community?.tokens[0]?.metadata?.config?.mintPrivate
                          ) && (
                            <div>
                              <h3 className='font-medium text-xs text-slate-500'>PRIVACY</h3>
                              <p className='mb-2 text-base font-medium text-slate-700'>
                                Access to this pass is private. Only people with the private mint
                                link can claim it.
                              </p>
                              <Button
                                size="sm"
                                leftIcon={faCopy}
                                onClick={() => {
                                  window.navigator.clipboard.writeText(`https://${community.domain}.withcomet.${getEnv() === 'prod' ? 'com' : 'dev'}/?mcode=${community.tokens[0]?.metadata?.config?.mintPassword}`);

                                }}
                                // variant="secondary"
                              >
                                Copy private mint link
                              </Button>
                            </div>
                          )
                        }

                        {
                          mintBlocked
                          && !community.needsClientLoaded
                          && community?.tokens[0]?.myBalance === 0
                          && (
                            <div>
                              <Alert variant="error">
                                <span className='text-base'>
                                  Access to this membership pass is private. You can only claim it
                                  if you have the correct link.
                                </span>
                              </Alert>
                            </div>
                          )
                        }

                        {/* <div>
                          <h3 className='font-medium text-xs text-slate-500'>PERMISSIONS</h3>
                          <p className='text-base font-medium text-slate-700'>
                            Holders of the pass can:
                          </p>
                          {
                            (tokenInfo.nftRoles && !!tokenInfo.nftRoles.length) && (
                              <ReadMore showHeight={60}>
                                {
                                  tokenInfo.nftRoles.map(role => (
                                    <div className='flex flex-row gap-2 items-center'>
                                      <FontAwesomeIcon icon={faCheck} className="w-3 h-3" />
                                      <p className='text-sm font-medium text-slate-700'>{humanReadableRoles[role]}</p>
                                    </div>
                                  ))
                                }
                              </ReadMore>
                            )
                          }
                        </div> */}
                    </div>

                    <div className="flex flex-col gap-3">
                      <div
                        className="relative overflow-hidden rounded-xl shadow-lg"
                        style={{ width: 126, height: 162 }}
                      >
                        {/* <div className="absolute" style={{ width: 126, height: 126 }}>
                            <img className="object-fill h-full w-full" src={tokenInfo.nftImage ? tokenInfo.nftImage : undefined} />
                          </div>
                          <div className="absolute bg-black" style={{ height: 36, width: 126, top: 126 }}></div>
                          <p style={{ fontSize: 11, top: 131, left: 9 }}  className="absolute font-bold text-white">#0</p>
                          <p style={{ fontSize: 6, top: 145, left: 9 }}  className="absolute font-bold text-white">{tokenInfo.name}</p> */}
                          <img src={tokenInfo.nftImage} className='w-full' />
                        </div>
                          {
                            user.loggedIn && (
                              <MintButton
                                roles={community.roles}
                                api={api}
                                tokenId={tokenInfo.id}
                                snowball={props.snowball}
                                user={props.user}
                                onMintSucceeded={() => {
                                  mutateCommunity();
                                }}
                                mintPassword={mcode}
                              />
                            )
                          }
                          {
                            !user.loggedIn && (
                              <Button
                                onClick={() => { setMintLoginModalOpen(true); }}
                                // loading={!props.snowball || mintRequestOut}
                                className='w-full'
                                size="base"
                                variant="primary"
                              >
                                <div className='flex flex-col'>
                                  <span className=''>Get Pass</span>
                                  {/* <span className='text-sm font-normal'>to claim</span> */}
                                </div>
                              </Button>
                            )
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* <div className="block lg:hidden py-2">
              {sidebar}
              <hr className='w-full mt-4' />
            </div> */}

            {!!roles && roles.includes('createPosts') && (
              <div className="mt-1 px-2 py-1">
                <Button
                  className="w-full"
                  size="lg"
                  variant="primary"
                  leftIcon={faPlus}
                  onClick={() => {
                    setComposerOpen(true)
                  }}
                >
                  Create New Post
                </Button>
                <ComposerDialog
                  open={composerOpen}
                  hasTitle={true}
                  value={post}
                  onChange={setPost}
                  onSubmit={onPostSubmit}
                  loading={postOut}
                  canCancel={true}
                  onCancel={() => {
                    setComposerOpen(false)
                    setPost({})
                  }}
                />
              </div>
            )}

            {/* Feed */}
            <div className="mt-2 px-2">
              {postsPrivate && (
                <Alert>
                  You don't have permission to view posts.
                  Claim the pass to get started.
                </Alert>
              )}
              {/* {
                feedLoading && (
                  loadingSection()
                )
              } */}
              {!feedLoading && flattenPosts(feed).length > 0 && (
                <div className="mb-10 flex flex-col gap-1 sm:p-1">
                  {
                    !!pinnedPosts && pinnedPosts.map((post) => {
                      // console.log(post);
                      return <Link
                        href={`/c/${community.domain}/p/${
                          slugifyText(post.title) || 'post'
                        }-${post.id}`}
                      >
                        <div className="block w-full cursor-pointer p-3 pr-4 transition-all hover:bg-slate-300 hover:bg-opacity-30 md:rounded-2xl">
                          <FeedPost
                            post={post}
                            onUpvote={starPinnedPost}
                            community={community}
                            gradientMask={true}
                            onPin={onPinPost}
                          />
                        </div>
                      </Link>
                    })
                  }
                  {flattenPosts(feed)
                  .filter(post => post.pinned !== true)
                  .map((post) => (
                    <Transition
                      show
                      enter="transition-opacity duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      key={'post_transition_' + post.id}
                    >
                      <Link
                        href={`/c/${community.domain}/p/${
                          slugifyText(post.title) || 'post'
                        }-${post.id}`}
                      >
                        <div className="block w-full cursor-pointer p-3 pr-4 transition-all hover:bg-slate-300 hover:bg-opacity-30 md:rounded-2xl">
                          <FeedPost
                            post={post}
                            onUpvote={starPost}
                            community={community}
                            gradientMask={true}
                            onPin={onPinPost}
                          />
                        </div>
                      </Link>
                    </Transition>
                  ))}
                </div>
              )}

              <div className="w-full p-6 text-center" ref={loadBoxRef}>
                {/* {
                  (newPostsRequestOut) ? <CircleLoader size={20} /> : <p>&nbsp;</p>
                } */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="lg:block hidden w-96 max-w-sm flex flex-col gap-3 p-3 sticky top-0 py-10 pl-2 pr-2">
          {sidebar}
        </div> */}
      </div>
    </Layout>
  )
}

Community.getInitialProps = ({ query }) => {
  return {
    query,
  };
}

export default withAuth(withCommunity(withSnowball(Community)), { authRequired: false });
