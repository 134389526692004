import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import classnames from "tailwindcss-classnames";
import Div100vh from 'react-div-100vh';
import Composer from "./composer";

function ComposerDialog(props) {

  const {
    open,
    onClose,
    title,
    width = 'base',
    buttons = [],

    value,
    onChange,
    onSubmit,
    loading,
    canCancel,
    onCancel,
    error = null,
  } = props;

  const closeFunction = () => {
    if (!canCancel) return;
    if (onCancel) onCancel();
    if (onClose) onClose();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-30 inset-0 overflow-y-auto" onClose={closeFunction}>
        <Div100vh>
          <div className="flex items-center justify-center pt-4 px-4 pb-20 text-center sm:p-0 min-h-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed backdrop-blur inset-0 bg-slate-600 bg-opacity-50 transition-opacity h-full" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block h-full sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={
                classnames(
                  'relative inline-block align-bottom bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle w-full',
                  {
                    'sm:max-w-lg': width === 'lg',
                    'sm:max-w-md': width === 'base',
                    'sm:max-w-sm': width === 'sm',
                  }
                )
              }>
                <Composer
                  hasTitle={true}
                  value={value}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  loading={loading}
                  canCancel={canCancel}
                  onCancel={onCancel}
                />
              </div>
            </Transition.Child>
          </div>
        </Div100vh>
      </Dialog>
    </Transition.Root>
  );
}

export default ComposerDialog;
