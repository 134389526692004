
import { faArrowDown, faArrowUp, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import GradientMask from './gradientMask';

export default function ReadMore(props) {
  const [expanded, setExpanded] = useState(false);
  const [clip, setClip] = useState(true);
  const [determineClip, setDetermineClip] = useState(false);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      if (props.showHeight > ref.current.offsetHeight && !determineClip) {
        setClip(false);
        setDetermineClip(true);
      }
    }
  }, [ref.current?.offsetHeight]);

  const { showHeight, children, align } = props;

  const style = {};

  if (expanded) {
    style.height = 'auto';
  } else {
    style.maxHeight = showHeight + 38;
    // style.position = 'relative';
  }

  return (
    <>
      <div style={style} ref={ref}>
        {
          !clip && props.children
        }
        {
          clip && (
            expanded
              ? (
                <>
                  { props.children }
                  <div className='w-full flex flex-col items-start rounded-full cursor-pointer' onClick={() => { setExpanded(!expanded) }}>
                    <div className='pb-1 pt-0 px-2 rounded-full bg-slate-400 bg-opacity-30'>
                      <a className='text-xs font-medium text-slate-800'>
                        COLLAPSE
                      </a>
                      <FontAwesomeIcon icon={faArrowUp} className="-mt-1 ml-1 h-3 w-3" />
                    </div>
                  </div>
                </>
                )
              : (
                  <div>
                    <GradientMask showHeight={showHeight}>
                      { props.children }
                    </GradientMask>
                    <div className='w-full flex flex-col items-start -mt-1 cursor-pointer' onClick={() => { setExpanded(!expanded) }}>
                      <div className='pb-1 pt-0 px-2 rounded-full bg-slate-400 bg-opacity-30'>
                        <a className='text-xs font-medium text-slate-800'>
                          EXPAND
                        </a>
                        <FontAwesomeIcon icon={faArrowDown} className="-mt-1 ml-1 h-3 w-3" />
                      </div>
                    </div>
                  </div>
                )
          )
        }
      </div>
    </>
  );
}
