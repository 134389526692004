
export const flattenPosts = (f) => {
  const result = [];
  if (!f) return result;

  for (let i = 0; i < f.length; i += 1) {
    const data = f[i];
    result.push(...data.posts);
  }
  return result;
};

export const onPostUpvote = async (
  postId,
  config,
) => {
  let cast = true;

  const {
    api,
    beforeUpdate,
    afterUpdate,
  } = config;

  const posts = flattenPosts(feed);
  let post = undefined;

  for (let i = 0; i < posts.length; i += 1) {
    if (posts[i].id === postId) {
      post = posts[i];

      let newVote = 1;
      if (post.myUpvote === newVote) {
        cast = false;
      }

      // first calculate what this new posts looks like
      let newVoteTotal = post.upvotes;
      if (post.myUpvote === undefined) {
        newVoteTotal += 1;
      } else {
        if (cast) {
          newVoteTotal = newVoteTotal - post.myUpvote + 1;
        } else {
          newVoteTotal -= 1;
        }
      }

      const newPost = {
        ...post,
        upvotes: newVoteTotal,
      };

      if (cast) {
        newPost.myUpvote = 1;
      } else {
        newPost.myUpvote = undefined;
      }

      const newFeed = [...posts];
      newFeed[i] = newPost;

      mutateFeed([{ posts: newFeed }], false);

      await api.post(`/community/${props.community.id}/posts/${postId}/upvote`, { cast, up: 1 });

      mutateFeed();

      break;
    }
  }
}
